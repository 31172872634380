import React from "react";
import Contact from "../components/pages/contact/contact";
import Chero from "../components/chero";
import Head from "../components/head";

const IndexPage = () => (
  <>
    <Head title="Contact Us" dis="Contact Us today and change your future for forever."/>

    <Chero herotitle=" Contact Us" />
    <Contact />
  </>
);

export default IndexPage;
