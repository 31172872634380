import React from "react";
import "./contact.scss";
export default () => {
  return (
    <div className="contact container">
      <div className="c-left">
        <div className="mobileNumbers">
          <span>Phone: </span>
          <div>
            <a href="tel:02037440480">020 3744 0480,</a>
            <a href="tel:07418611406">0741 861 1406</a>
          </div>
        </div>
        <p>
          <span>Email: </span>
          <a href="mailto:info@premisehealthcare.co.uk">
            info@premisehealthcare.co.uk
          </a>
        </p>
        <p>Also you can always send us an email via form below:</p>
        <form
          className="form"
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="f-left">
            <input type="text" name="name" placeholder="Your Name" />
            <input type="email" name="email" placeholder="Email" />
            <input type="tel" name="mobile" placeholder="Mobile Number" />
          </div>
          <div className="c-right">
            <textarea
              name="Message"
              id=""
              cols="30"
              rows="10"
              placeholder="Any Message For Us"
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
      <div className="c-right">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.90790472146!2d-0.12612132352961986!3d51.51490557181499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ccaaa0b0b7%3A0xbe144a0754857ae1!2s71-75%20Shelton%20St%2C%20London%20WC2H%209JQ%2C%20UK!5e0!3m2!1sen!2sin!4v1713805106416!5m2!1sen!2sin"
          width="600"
          height="450"
          frameborder="0"
          allowfullscreen=""
          title="Google Map"
        ></iframe>
      </div>
    </div>
  );
};
