import React from "react";
import "./heros.scss";
export default (props) => {
  return (
    <div className="au-hero">
      <div className="container">
        <p>{props.herotitle}</p>
      </div>
    </div>
  );
};
